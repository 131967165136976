<template>
  <div class="flex-1 flex flex-col px-16 py-8">
    <div class="flex-1 text-center">
      <!-- Name + Description -->
      <div>
        <h2 class="mb-0">{{edition.name}}</h2>

        <p class="mt-2 text-lg text-gray-500 dark:text-gray-400">
          {{edition.description}}
        </p>
      </div>
    </div>

    <!-- Buy button -->
    <template v-if="edition.price > 0">
      <div class="mt-2 text-center">
        <div class="my-4">
          <c-btn
            kind="primary"
            :title="`Buy Craft CMS ${edition.name}`"
            :href="craftIdUrl + '/buy-cms/' + edition.handle"
            block
            large
          >
            ${{edition.price}}
          </c-btn>

          <div class="mt-2 text-gray-500 dark:text-gray-400">
            Plus {{ $filters.currency(edition.renewalPrice) }}/year after one year.
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import {toRefs} from '#imports';
import {useEnv} from '~/helpers/env';

const props = defineProps<{
  edition: object;
  previousEdition?: object;
}>();

const {edition} = toRefs(props);
const {craftIdUrl} = useEnv();
</script>
