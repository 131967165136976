<template>
  <div class="craft-pro bg-gray-100 dark:bg-gray-800 mb-8 rounded-lg">

    <div class="flex items-center gap-6 p-8">
      <img
        alt="Craft CMS icon"
        src="../assets/images/craft.svg"
        width="60"
        height="60"
      />

      <div>
        <h2 class="m-0">Craft CMS</h2>

        <p>
          <a href="https://craftcms.com/pricing" title="Craft CMS pricing options">
            Learn more about Craft CMS editions →
          </a>
        </p>
      </div>
    </div>

    <div class="md:flex border-t">
      <template v-for="(edition, editionKey) in editions" :key="editionKey">
        <CraftEdition
          :edition="edition"
          :previous-edition="editions[editionKey - 1]"
          :class="{
            'border-t md:border-t-0 md:border-l': editionKey > 0,
          }"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import CraftEdition from '~/components/CraftEdition.vue';

  export default {
    components: {CraftEdition},
    data() {
      return {
        editions: [
          {
            name: 'Team',
            handle: 'team',
            price: 279,
            renewalPrice: 99,
            description: 'For marketing sites managed by small teams.',
          },
          {
            name: 'Pro',
            handle: 'pro',
            price: 399,
            renewalPrice: 99,
            description: 'For everything else.',
          },
        ],
      };
    },
  };
</script>
