<template>
  <div class="xcontainer space-y-12">
    <template v-if="featuredSections">
      <div
        v-for="(featuredSection, featuredSectionKey) in featuredSections"
        :key="featuredSectionKey"
        class="featured-section"
      >
        <c-link
          class="float-right"
          :to="'/featured/' + featuredSection.slug"
          :title="'See all “' + featuredSection.title + '” plugins'"
        >
          {{ 'See all' }}
        </c-link>
        <div>
          <h2 class="mb-4">
            {{ featuredSection.title }}
          </h2>
          <div class="mb-8">
            <plugin-grid
              v-if="featuredSection.plugins"
              :plugins="featuredSection.plugins"
              :auto-limit="true"
            />
          </div>
        </div>
      </div>
    </template>

    <CraftLicenses />
  </div>
</template>

<script setup lang="ts">
  import PluginGrid from '../components/PluginGrid.vue';
  import {
    computed,
    definePageMeta,
    useAppStore,
    usePluginStoreStore,
    useAsyncData,
  } from '#imports';
  import CraftLicenses from '~/components/CraftLicenses.vue';

  definePageMeta({
    layout: 'site',
  });

  const appStore = useAppStore();
  const pluginStoreStore = usePluginStoreStore();

  const featuredSections = computed(() => pluginStoreStore.featuredSections);

  useAsyncData(async () => {
    appStore.updatePageMeta({
      title: 'Craft Plugin Store',
      description: 'Plugins for Craft CMS',
    });

    await pluginStoreStore.getFeaturedSections();
  });
</script>
